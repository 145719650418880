import vue from 'vue'
import auth from '@/middleware/auth.js';

let rutas = {
    path: '',
    component: () =>
        import ('@/layouts/Constelacion'),
    children: [
        {
            path: '/solicitudes',
            name: 'solicitudes',
            component: () => import('@/apps/solicitudes/pages/Dashboard'),
            meta: {
                title: 'Créditos',
                middleware: auth
            }
        }
    ]
};

export default rutas;